<template>
  <v-container fluid class="down-top-padding">
    <BaseBreadcrumb :title="page.title" :icon="page.icon" :breadcrumbs="breadcrumbs"></BaseBreadcrumb>
    <v-row>
      <v-col cols="12" md="12">
        <v-card>
          <v-toolbar dense flat>
            <v-toolbar-title>Prodotti  CS-CART</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon>
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </v-toolbar>
          <v-divider></v-divider>

          <v-row>
            <v-col cols="12">
              <v-btn class="mr-2 ml-2" color="primary" dark rounded  @click="editProduct" >
                <v-icon>mdi-pencil-outline</v-icon>
                <span class="d-none d-md-block">Edit Product</span>
              </v-btn>
              <v-btn class="mr-2 ml-2" color="warn" dark rounded  @click="deleteProduct" >
                <v-icon>mdi-delete</v-icon>
                <span class="d-none d-md-block">Delete Product</span>
              </v-btn>
            </v-col>

          </v-row>

          <v-card-title>
            <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
            ></v-text-field>
          </v-card-title>

          <v-card-text>

            <v-data-table
                :headers="headers"
                :items="rows"
                :items-per-page="50"
                :search="search"
                v-model="selected"
                selectable-key="selected"
                :single-select="singleSelect"
                item-key="product_id"
                show-select
                class="elevation-1"
            >


            </v-data-table>

          </v-card-text>
        </v-card>
      </v-col>

    </v-row>


  </v-container>
</template>

<script>
import moment from "moment";
import axios from "axios";
// import store from "@/store";
import {db} from '@/firebase';

export default {
  name: "CS-CART_PRODUCTS",
  data: () => ({
    search: '',
    selected: [],
    singleSelect: true,
    headers: [
      {
        text: 'ID Prodotto',
        value: 'product_id',
      },
      {
        text: 'CODICE ARTICOLO',
        value: 'product_code',
      },
      {
        text: 'DESCRIZIONE ARTICOLO',
        value: 'product',
      },
      {
        text: 'GIACENZA',
        value: 'amount',
      },
      {
        text: 'MAIN CATEGORY',
        value: 'main_category',
      },
      {
        text: 'PREZZO',
        value: 'price',
      }
    ],
    page: {
      title: "CS-CART"
    },
    rows: [],
    elementVisible: false,
    breadcrumbs: [
      {
        text: "CS-CART",
        disabled: true
      }
    ],
  }),
  mounted() {
    this.$binding("rows", db
        .collection('farmacie')
        .doc(this.$route.params.id)
        .collection('cs_cart_products'))
  },
  methods: {
    getDisplayDate(dateObject) {
      let jsDate = new Date(dateObject.seconds * 1000);
      return moment(jsDate).format('DD/MM/YYYY');
    },
    editProduct () {
      if (this.selected.length) {
       // console.log("this.selected 124",this.selected);
        this.$router.push( {name: 'CS_CART_Detail', params: { data: this.selected[0], pharmacy_id: this.$route.params.id }})
      } else {
        this.showNotification('Please select a product first.', false);
      }
    },
    deleteProduct() {
      if (this.selected.length) {
      const config = {
        method: 'delete',
        url: 'https://cors-anywhere.herokuapp.com/https://www.farmania05.it/pisilli/api/products/' + this.selected[0].product_id,
        headers: {
          'Authorization': 'Basic YW1taW5pc3RyYXppb25lQGZhcm1hbmlhLml0OjBCNTVDODdvMjJxbzY3bHM3SU84TzI0NzMxTTI4Yjho',
          'Content-Type': 'application/json'
        }
      };

      axios(config)
          .catch(function (error) {
            this.showNotification(error.toLocaleString(), false)
            return
          }).finally(() => {
            //update the subcollection of cs cart product
              db.collection('farmacie')
                .doc(this.$route.params.id)
                .collection('cs_cart_products')
                  .doc(this.selected[0].product_id)
                  .delete()
                  .then(result => {
                    this.showNotification('Deleted Successfully', true);
                    console.log("result",result )
                  }).catch(error => {
                    console.log("error 176", error)
              });
      });
      } else {
        this.showNotification('Please select a product first.', false);
      }

    },
    showNotification(message, isSuccess) {
      console.log('L  ===', message + isSuccess);
    }
  }
};
</script>
